import { SuperModel } from './super-model.model';

export class WorkDays extends SuperModel {
  enabled: boolean;
  timeZone: string;
  mon: WorkDay;
  tue: WorkDay;
  wed: WorkDay;
  thu: WorkDay;
  fri: WorkDay;
  sat: WorkDay;
  sun: WorkDay;

  constructor(data: Partial<WorkDays> = {}) {
    super(data);
  }

  get serialised() {
    return {
      enabled: this.enabled,
      timeZone: this.timeZone,
      mon: this.mon,
      tue: this.tue,
      wed: this.wed,
      thu: this.thu,
      fri: this.fri,
      sat: this.sat,
      sun: this.sun,
    };
  }
}

export class WorkDay extends SuperModel {
  isOpen: boolean;
  hours: Array<Hour>;

  constructor(data: Partial<WorkDay> = {}) {
    super(data);
    if (this.hours && this.hours.length) {
      this.hours = this.hours.map(hour => new Hour(hour));
    }
  }
  get serialised() {
    return {
      isOpen: this.isOpen,
      hours: this.hours?.map(hour => hour.serialised),
    };
  }
}

export class Hour extends SuperModel {
  from: string;
  to: string;

  constructor(data = {}) {
    super(data);
  }
  get serialised() {
    return {
      from: this.from,
      to: this.to,
    };
  }
}
