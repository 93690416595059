import { Injectable, Injector } from '@angular/core';

import { initDynamicLayoutUrl } from '@bitf/utils/bitf-responsive.utils';

import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
// import { BitfTagManagerService } from '@bitf/services/tag-manager/bitf-tag-manager.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';
import { BitfTextToSpeechService } from '@common/libs/bitforce/core/services/text-to-speech/bitf-text-to-speech.service';

import { ResponsiveService } from '@services';
import { WarehousesFilterService } from './warehouses-filter.service';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  warehouseFilterService: WarehousesFilterService;
  constructor(
    // private bitfTagManagerService: BitfTagManagerService,
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private responsiveService: ResponsiveService,
    private bitfTextToSpeechService: BitfTextToSpeechService,
    protected injector: Injector
  ) {
    super(injector);
    this.warehouseFilterService = injector.get<WarehousesFilterService>(WarehousesFilterService);
  }

  async init() {
    await super.init();
    // NOTE: At this point translations are already loaded

    // NOTE: needed for mobile/desktop routing support
    initDynamicLayoutUrl(this.router, this.storeService);

    this.initBreakpointListener();
    this.responsiveService.init();

    // this.bitfTagManagerService.init();

    // PWA
    // this.bitfPwaService.initOnlineChecker();
    // this.bitfPwaService.showDefaultOnlineStateChangeToast();
    // if (environment.registerServiceWorker) {
    //   this.bitfPwaService.init();
    //   this.bitfPwaService.initManifest();
    //   this.bitfPwaService.initSwUpdate();
    //   this.bitfPwaService.handleSwUpdate();
    //   this.bitfPwaService.initBeforeInstallPrompt();
    //   this.bitfPwaService.checkForBeforeInstallPromptEvent();
    // }

    this.bitfMetadataService.setDefaultDescription();

    this.bitfTextToSpeechService.init().then(() => {
      this.bitfTextToSpeechService.syncVoiceLanguageLocaleWithLocale();
    });
    this.warehouseFilterService.init();
  }

  initLogSender() {
    this.bitfLogDnaSenderService.init();
  }
}
