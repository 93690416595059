import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EBitfCloseEventStatus } from '@enums';
import { IBitfCloseEvent, ReassingDialogData } from '@bitf/interfaces';

@Component({
  selector: 'soh-reassign-dialog',
  templateUrl: './reassign-dialog.component.html',
  styleUrls: ['./reassign-dialog.component.scss'],
})
export class ReassignDialogComponent implements OnInit {
  form: UntypedFormGroup;
  okButtonType: ThemePalette;

  constructor(
    public dialogRef: MatDialogRef<ReassignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ReassingDialogData,
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array(this.dialogData.items.map(item => this.createItemFormGroup(item))),
      selectedReason: [''],
    });
  }

  createItemFormGroup(item: any): FormGroup {
    return this.formBuilder.group({
      sku: [item.sku],
      qty: [1],
    });
  }

  increment(index: number, item: any) {
    const control = (this.form.get('items') as FormArray).at(index).get('qty');
    if (item.qty > control.value) {
      control.setValue(control.value + 1);
    }
  }

  decrement(index: number, item: any) {
    const control = (this.form.get('items') as FormArray).at(index).get('qty');
    if (control.value > 0) {
      control.setValue(control.value - 1);
    }
  }

  isIncrementDecrementDisabled(item: any) {}

  getFormNumberValue(index: number) {
    const control = (this.form.get('items') as FormArray).at(index).get('qty');
    return control.value;
  }

  ngOnInit() {
    switch (this.dialogData.okButtonType) {
      // NOTE all possible material values are primary, accent, or warn
      case 'PRIMARY':
        this.okButtonType = 'primary';
        break;
      case 'SECONDARY':
        this.okButtonType = 'accent';
        break;
      case 'WARNING':
        this.okButtonType = 'warn';
        break;
      case 'DANGER':
        this.okButtonType = 'warn';
        break;
      default:
        this.okButtonType = 'primary';
    }
  }

  getcolumnIdentifiers(columnsToDisplay: any[]): string[] {
    let columns = columnsToDisplay.map(column => column.property);
    return columns;
  }

  onOk() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.form && this.form.value,
    } as IBitfCloseEvent<void>);
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
