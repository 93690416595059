import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'soh-material-icon-provider',
  templateUrl: './material-icon-provider.component.html',
  styleUrls: ['./material-icon-provider.component.scss'],
})
export class MaterialIconProviderComponent {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'dashboard-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'return-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/returns_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'orders-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/orders_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'click-and-collect-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/click_collect_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'ship-from-store-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ship_from_store_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'reassign-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/reassign_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'fulfillments-reassign-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fulfillments-reassign.svg')
    );
    iconRegistry.addSvgIcon(
      'shop-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/shop_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'warehouse-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/warehouse_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'store-manager-badge-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/store_manager_badge.svg')
    );
    iconRegistry.addSvgIcon('o2o', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/o2o.svg'));
    iconRegistry.addSvgIcon(
      'settings-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg')
    );
    iconRegistry.addSvgIcon(
      'sales-channels-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sales_channels_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'settings-sc-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'moon-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/moon_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'fulfillments-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fulfillments_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'delete-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg')
    );
  }
}
