import { Injectable, Injector } from '@angular/core';
import { IBitfApiRequest } from '@common/interfaces';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class ShipFromStoreService extends BitfApiService {
  constructor(public injector: Injector) {
    super('o2o/ship-from-store', injector);
  }

  getWorkflow(apiRequest: IBitfApiRequest) {
    return this.action({ path: '/workflow-transitions', modelMapper: 'workflow', ...apiRequest });
  }
}
