<soh-expandable-panel [title]="title"
  [class]="className"
  class="h-100"
  *ngIf="items">
  <div>
    <soh-dynamic-table [dataSource]="items"
      *ngIf="!isInEditMode"
      [allColumns]="[
    {
      property: 'label',
      title: '',
      template:labelTemplate,
      classRow: 'pr-0'
    },
    {
      property: 'value',
      title: '',
      template: valueTemplate,
      classRow: 'pr-0'
    }
    ]"
      tableClass="without-header">
    </soh-dynamic-table>

    <div *ngIf="isInEditMode">
      <form [formGroup]="form">
        <div *ngFor="let item of items">
          <mat-form-field appearance="outline"
            class="w-100">
            <mat-label>{{item.label| translate}}</mat-label>
            <input matInput
              [formControlName]="item.formKey"
              [id]="item.formKey"
              placeholder="{{item.label| translate}}">
          </mat-form-field>
        </div>
        <div class="text-right">
          <button mat-fab
            extended
            color=""
            class="mr-3"
            (click)="onCancel()"
            type="button">{{'COMMON.LABEL.CANCEL'| translate}}</button>
          <button mat-fab
            extended
            (click)="onUpdate()"
            color="accent"
            [disabled]=form.invalid
            type="submit">{{'COMMON.LABEL.SAVE'| translate}}</button>
        </div>
      </form>

    </div>
  </div>
  <div class="text-right"
    *ngIf="isEditable && !isInEditMode">
    <button mat-mini-fab
      color=""
      (click)="edit()">
      <mat-icon>edit_note</mat-icon>
    </button>
  </div>



</soh-expandable-panel>

<ng-template #labelTemplate
  let-item="item">
  <div class="cell-with-border blue-text font-medium">
    {{item.label | translate}}
  </div>
</ng-template>

<ng-template #valueTemplate
  let-item="item">
  <div class="cell-with-border">
    <ng-container *ngIf="item.specialLink;else pureValueTemplate">
      <a [href]="item.specialLink">
        <ng-container *ngIf="item.translateValue; else valueWithoutTranslation">
          {{item.value | translate}}
        </ng-container>
      </a>
    </ng-container>
    <ng-template #pureValueTemplate>
      <ng-container *ngIf="item.translateValue; else valueWithoutTranslation">
        {{item.value | translate}}
      </ng-container>
    </ng-template>
    <ng-template #valueWithoutTranslation>
      {{item.value}}
    </ng-template>
  </div>
</ng-template>