import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

import { BitfAuth0AuthService } from '@bitf/services/auth/auth0/bitf-auth0-auth.service';

import { BITF_CONFIGS } from '@configs';
import { User } from '@models';
import { UsersService } from '@services';
import { IBitfApiResponse } from '@common/interfaces';
import { CONSTANTS } from '@common/constants/common.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfAuth0AuthService {
  usersService: UsersService;
  breakpointObserver: BreakpointObserver;
  router: Router;

  constructor(protected injector: Injector) {
    super(injector);
    this.usersService = this.injector.get(UsersService);
    this.router = this.injector.get(Router);

    this.initAuth0();
  }

  loginUser() {
    /**
     * Use this and remove override of loginUserWithToken when /me endpoint is available
     */
    this.usersService.getMe().subscribe(
      (response: IBitfApiResponse<User>) => {
        this.setUser(response.content);
        this.onLoginSuccess();
        this.handleRedirect();
      },
      errror => {
        this.onLoginError(errror.message);
      }
    );
  }

  handleRedirect() {
    this.router.navigate([BITF_CONFIGS.urls.homePageUrl], { replaceUrl: true, fragment: null });
  }

  initAuth0() {
    let redirectUri: string;
    let returnTo: string;
    redirectUri = `${location.origin}/auth/sign-in`;
    returnTo = `${location.origin}/auth/sign-in`;

    Object.assign(CONSTANTS.auth0Config, {
      redirectUri,
      returnTo,
    });
    super.initAuth0();
  }
}
