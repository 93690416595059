import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';
// eslint-disable-next-line max-len
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { ReassignDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/reassignment/reassign-dialog.component';

export const WEB_CONSTANTS = {
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  reassignDialogComponent: ReassignDialogComponent,
  VALIDATION_MESSAGES: {
    verificationCustomerCode: bitfToTranslate('PICKUP_IN_STORE.DIALOG_CONFIRM_PICKUP.INVALID_CODE_MESSAGE'),
  },
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
