// REF:
// https://auth0.com/docs/libraries/auth0js/v9

import { Injector } from '@angular/core';
import * as auth0 from 'auth0-js';

import { IBitfAuth0TokenMetadata, IBitfAuth0Login } from './bitf-auth0-auth.interface';
import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';
import { BitfAuthService } from '../bitf-auth.service';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { EBitfAuthState } from '@enums';
import { CONSTANTS } from '@constants';

export abstract class BitfAuth0AuthService extends BitfAuthService<IBitfAuth0Login, IBitfAuth0TokenMetadata> {
  private auth0: auth0;
  private authParams = CONSTANTS['auth0Config'];

  constructor(protected injector: Injector) {
    super(injector);
  }

  signIn(loginResponse: IBitfAuth0Login) {
    super.signIn(loginResponse);
    this.activateRefreshToken();
  }

  @BitfTryCatch()
  auth0Login() {
    this.auth0.authorize();
  }

  @BitfTryCatch()
  handleAuthentication(notUsed?: any) {
    this.auth0.parseHash((error, authResult: IBitfAuth0Login) => {
      if (authResult && authResult.accessToken) {
        this.signIn(authResult);
      } else if (error) {
        // NOTE: In case of unverified email will be
        // {error: 'unauthorized', errorDescription: 'Please verify your email before logging in.' }
        setTimeout(() => {
          let errorMessage = `${error.error} - ${error.error_description || error.errorDescription}`;
          if (
            error.error === 'unauthorized' &&
            error.errorDescription === 'Please verify your email before logging in.'
          ) {
            errorMessage = this.translateService.instant(bitfToTranslate('BITF.AUTH.EMAIL_NOT_VERIFIED'));
          }
          this.onLoginInfo(errorMessage, EBitfAuthState.EMAIL_NOT_VERIFIED);
        }, 0);
      }
    });
  }

  @BitfTryCatch()
  decodeToken(loginResponse: IBitfAuth0Login | any): IBitfAuth0TokenMetadata | any {
    if (!loginResponse) {
      return undefined;
    }
    return {
      // NOTE: keep the original also if is not in the interface
      ...loginResponse,
      token: loginResponse.accessToken,
      expiresAt: Date.now() + loginResponse.expiresIn * 1000,
      expiresIn: loginResponse.expiresIn * 1000,
    } as IBitfAuth0TokenMetadata;
  }

  @BitfTryCatch()
  async renewToken(): Promise<IBitfAuth0Login> {
    return new Promise((success, error) => {
      this.auth0.checkSession(this.authParams, (e, response: IBitfAuth0Login) => {
        if (response && response.accessToken) {
          this.signIn(response);
          success(response);
        } else {
          this.signIn(undefined);
          error(e);
        }
      });
    });
  }

  getUserInfo<T>(): Promise<T> {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(this.authTokenMetaData.accessToken, (error, user) => {
        if (error) {
          reject(error);
        } else {
          resolve(user);
        }
      });
    });
  }

  signOut() {
    this.auth0.logout({
      clientID: this.authParams.clientID,
      returnTo: this.authParams.returnTo,
    });
    super.signOut();
  }

  initAuth0() {
    this.auth0 = new auth0.WebAuth(this.authParams);
  }
}
