<mat-toolbar class="d-flex border-top fixed-bottom">

  <div class="justify-content-start d-flex">
    <ng-content select="[footer-action-left]"></ng-content>
  </div>

  <span class="flex-grow-1"></span>

  <div class="justify-content-end d-flex">
    <ng-content select="[footer-action-right]"></ng-content>
  </div>

</mat-toolbar>