import { SuperModel } from './super-model.model';

export class Address extends SuperModel {
  firstName?: string;
  lastName?: string;
  company?: string;
  street?: string;
  city?: string;
  postcode?: string;
  province?: string;
  country?: string;
  phone?: string;

  constructor(data: any = {}) {
    super(data);
  }
  get serialised() {
    return {};
  }
}
