<div *ngIf="users">
  <mat-list>
    <div *ngIf="users?.length === 0">
      <div class="d-flex justify-content-start flex-wrap border-top gap-2 pt-3 pb-3">
        <div class="col-24 d-flex justify-content-start mb-0">
          <div class="align-self-center">
            {{ 'COMMON.LABEL.NO_USERS_FOUND' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let user of users">
      <div class="d-flex justify-content-start flex-wrap border-top gap-2 pt-3 pb-3">
        <div
          class="border rounded-circle d-flex justify-content-center mb-0 store-user-avatar align-self-center bg-primary-medium text-black">
          <div class="row ">
            <div class="align-self-center">
              {{ user?.getShortName() }}
            </div>
          </div>
        </div>
        <div class="col-sm-10 col-14 d-flex justify-content-start mb-0 pl-0">
          <div class="align-self-center"
            [ngClass]="storeService.store.activeBreakpoints.isHandset ? 'font-moblie-small':''">
            {{ user?.fullName }}
            <div>
              <a>{{ user?.email }}</a>
            </div>
            <div
              *ngIf="storeService.store.activeBreakpoints.isHandset  && !storeService.store.activeBreakpoints.isHandsetLandscape"
              class="pt-1">
              <div class="d-flex align-items-center"
                *ngIf="user?.role">
                <div class="px-3 rounded mat-caption text-center py-1 font-small email-label">
                  <p>{{ 'USERS.ROLE.'+user?.role | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-0"
          *ngIf="!storeService.store.activeBreakpoints.isHandset || storeService.store.activeBreakpoints.isHandsetLandscape"
          [ngClass]="!showChangeRoleButton && !showRemoveButton ? 'justify-content-end col-sm-11':'justify-content-center col-sm-8'">
          <div class="d-flex justify-content-center align-items-center"
            *ngIf="user?.role">
            <div class="px-3 rounded mat-caption text-center py-1 email-label">
              {{ 'USERS.ROLE.'+user?.role | translate }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-0 align-self-center col-2"
          *ngIf="showChangeRoleButton || showRemoveButton">
          <button mat-mini-fab
            *ngIf="showRemoveButton"
            class="btn-secondary-dark mr-2"
            aria-label="unlink"
            (click)="onRemoveUser(user)">
            <mat-icon color="primary"
              class="align-self-center">person_remove</mat-icon>
          </button>
          <button mat-mini-fab
            *ngIf="showChangeRoleButton"
            class="btn-secondary-dark"
            aria-label="unlink"
            (click)="onChangeRole(user)">
            <mat-icon color="primary"
              class="align-self-center">edit_document</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-list>
  <mat-divider *ngIf="users?.length"></mat-divider>

</div>