import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { User } from '@models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector) {
    super('usr/users', injector);
  }

  /**
   * Get user details
   *
   * @returns
   */
  getMe(): Observable<IBitfApiResponse<User>> {
    return super.request<User>({
      path: '/me',
      method: 'GET',
      disableHideLoader: true,
    });
  }
}
