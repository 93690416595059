<ng-container *ngIf="formItem && formItem.isVisible"
  [ngSwitch]="formItem.type">

  <!-- Input -->
  <mat-form-field *ngSwitchCase="'text'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      *bitfUiRoleManager="componentConfig.uiRole"
      type="text"
      [placeholder]="formItem.placeholder || formItem.label"
      [formControl]="formControl" />
  </mat-form-field>


  <mat-form-field *ngSwitchCase="'number'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      *bitfUiRoleManager="componentConfig.uiRole"
      type="number"
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      [placeholder]="formItem.placeholder || formItem.label"
      [formControl]="formControl" />
  </mat-form-field>

  <mat-form-field *ngSwitchCase="'mask'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      *bitfUiRoleManager="componentConfig.uiRole"
      type="text"
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      [placeholder]="formItem.placeholder || formItem.label"
      [formControl]="formControl">
  </mat-form-field>

  <!-- Autocomplete -->
  <mat-form-field [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [appearance]="componentConfig.appearance || 'outline'"
    *ngSwitchCase="'autocomplete'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      [placeholder]="formItem.placeholder || formItem.label"
      *bitfUiRoleManager="componentConfig.uiRole"
      [matAutocomplete]="auto"
      [formControl]="formControl"
      (blur)="onAutocompleteBlur()">

    <mat-icon matSuffix
      *ngIf="!showLoader">search</mat-icon>
    <mat-spinner matSuffix
      *ngIf="showLoader"
      [diameter]="20"></mat-spinner>
    <mat-autocomplete #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onSelectionChange($event)">
      <mat-option *ngFor="let data of componentConfig.autocompleteData"
        [value]="componentConfig?.useObject ? data : data?.id">
        {{data['label']}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Autocomplete with chips -->
  <mat-form-field [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [appearance]="componentConfig.appearance || 'outline'"
    *ngSwitchCase="'autocomplete-chips'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let chip of chips; let i = index;"
        [removable]="!formItem.isReadOnly && formItem.isEditable && !formItem.isDisabled"
        (removed)="removeChip(i)">
        {{chip}}
        <button matChipRemove
          *ngIf="!formItem.isReadOnly && formItem.isEditable && !formItem.isDisabled">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input matInput
        #chipInput
        [readonly]="formItem.isReadOnly || !formItem.isEditable"
        [placeholder]="formItem.placeholder || formItem.label"
        *bitfUiRoleManager="componentConfig.uiRole"
        [matChipInputFor]="chipList"
        [matAutocomplete]="auto"
        matChipInputAddOnBlur="true"
        (matChipInputTokenEnd)="onMatChipInputTokenEnd($event)"
        [formControl]="formControl">
    </mat-chip-grid>
    <mat-icon matSuffix
      *ngIf="!showLoader">search</mat-icon>
    <mat-spinner matSuffix
      *ngIf="showLoader"
      [diameter]="20"></mat-spinner>
    <mat-autocomplete #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onChipsSelectionChange($event)">
      <mat-option *ngFor="let data of filteredAutocompleteData"
        [value]="componentConfig?.useObject ? data : data?.id">
        {{data['label']}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Date -->
  <mat-form-field *ngSwitchCase="'date'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      *bitfUiRoleManager="componentConfig.uiRole"
      [matDatepicker]="pickerDate"
      [min]="formItem.metaData?.minDate"
      [max]="formItem.metaData?.maxDate"
      [placeholder]="formItem.placeholder || formItem.label"
      [formControl]="formControl"
      [readonly]="true"
      (dateChange)="onDateChange()"
      (click)="toggleDatePicker()" />
    <button mat-icon-button
      *ngIf="!formItem.isReadOnly && formItem.isEditable && formControl.value"
      matSuffix
      type="button"
      (click)="deleteValue()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker-toggle matPrefix
      *bitfUiRoleManager="hiddenUIRole"
      [for]="pickerDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngSwitchCase="'date-range'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [appearance]="componentConfig.appearance || 'outline'">
    <mat-label>{{ formItem.label }}</mat-label>
    <mat-date-range-input [rangePicker]="dateRangePicker">
      <input matStartDate
        (dateChange)="onDateChange()"
        [placeholder]="formItem.placeholder || formItem.label">
      <input matEndDate
        (dateChange)="onDateChange()"
        [placeholder]="formItem.placeholder || formItem.label">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix
      [for]="dateRangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
  </mat-form-field>

  <!-- Textarea -->
  <mat-form-field *ngSwitchCase="'textarea'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <textarea matInput
      cdkTextareaAutosize
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="10"
      [formControl]="formControl"
      *bitfUiRoleManager="componentConfig.uiRole"
      [placeholder]="formItem.placeholder || formItem.label"></textarea>
  </mat-form-field>

  <!-- Checkbox -->
  <div *ngSwitchCase="'checkbox'">
    <mat-checkbox [disabled]="formItem.isDisabled || !canAction()"
                  color="primary"
                  class="text-left"
                  [formControl]="formControl"
                  (change)="onSelectionChange($event, $event.source)">
      <span class="label-wrap">{{ formItem.label }}</span>
    </mat-checkbox>
  </div>

  <!-- Slide Toggle -->
  <div *ngSwitchCase="'slide-toggle'"
    class="mb-3">
    <mat-slide-toggle [disabled]="formItem.isDisabled || !canAction()"
      color="primary"
      [formControl]="formControl">{{ formItem.label }}</mat-slide-toggle>
  </div>

  <!-- Chips -->
  <div *ngSwitchCase="'chips'"
    class="mb-3">
    <mat-chip-listbox [formControl]="formControl"
      [multiple]="multiple"
      [disabled]="formItem.isDisabled || !canAction()"
      (change)="onSelectionChange($event)">
      <mat-chip-option *ngFor="let option of formItem.options"
        [value]="option.id"
        [selected]="isChipSelected(option.id)"
        color="primary">
        {{option.label}}
        <!-- <mat-icon matChipRemove>cancel</mat-icon> -->
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</ng-container>


<ng-template #inputTextNumeric>

</ng-template>