import { SuperModel } from './super-model.model';
import { EUsersRole } from '@common/enums/users-role.enum';

export class User extends SuperModel {
  id: string;
  authId: string;
  email: string;
  role: EUsersRole;
  tenant: string;
  firstName: string;
  lastName: string;
  fullName: string;
  stores: any[];

  constructor(data = {}) {
    super(data);
  }
  getShortName() {
    return this.fullName
      .split(' ')
      .map(name => name.substring(0, 1))
      .join('');
  }

  get serialised() {
    // FIXME
    return {
      id: this.id,
      email: this.email,
      fullName: this.fullName,
      role: this.role,
      authId: this.authId,
      firstName: this.firstName,
      lastName: this.lastName,
      tenant: this.tenant,
      stores: this.stores,
    };
  }

  get canViewLogs() {
    return true;
  }
}
