<mat-form-field appearance="outline"
  class="w-100 {{formFieldClass}}"
  [ngStyle]="{'font-size': fontSize}"
  [ngClass]="{'form-density-3 button-density-3 form-field-condensed': isInToolbar, 'inverted-color': invertedColor}"
  subscriptSizing="dynamic">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input matInput
    [formControl]="formControl"
    [placeholder]="placeholder">
  <mat-icon matPrefix
    *ngIf="hasSearchIcon">search</mat-icon>

  <button mat-icon-button
    class="mr-1"
    matSuffix
    (click)="clearSearchField()"
    *ngIf="hasCancelButton && formControl.value">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>