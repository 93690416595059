import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ISimpleListItem } from '@web/shared/components/simple-list/simple-list.interface';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { Address } from '@models';

@Component({
  selector: 'soh-address-details',
  templateUrl: './address-details.component.html',
  styleUrl: './address-details.component.scss',
})
export class AddressDetailsComponent implements OnInit, OnChanges {
  @Input() address: Address;
  @Input() title: string;
  @Input() isEditable = false;
  @Input() isRequired = false;
  @Input() className = '';

  @Output() updateAddress = new EventEmitter<Address>();

  items: ISimpleListItem[];
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address?.currentValue) {
      const { firstName, lastName, street, postcode, city, province, country, phone } =
        changes.address?.currentValue;
      if (this.address?.firstName && this.address?.lastName) {
        this.items = [
          {
            label: bitfToTranslate('COMMON.LABEL.NAME'),
            value: firstName,
            formKey: 'firstName',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.SURNAME'),
            value: lastName,
            formKey: 'lastName',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.STREET'),
            value: street,
            formKey: 'street',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.POSTALCODE'),
            value: postcode,
            formKey: 'postcode',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.CITY'),
            value: city,
            formKey: 'city',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.PROVINCE'),
            value: province,
            formKey: 'province',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.COUNTRY'),
            value: country,
            formKey: 'country',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.PHONE'),
            value: phone,
            formKey: 'phone',
            required: false,
          },
        ];
      } else {
        this.items = [
          {
            label: bitfToTranslate('COMMON.LABEL.STREET'),
            value: street,
            formKey: 'street',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.POSTALCODE'),
            value: postcode,
            formKey: 'postcode',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.CITY'),
            value: city,
            formKey: 'city',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.PROVINCE'),
            value: province,
            formKey: 'province',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.COUNTRY'),
            value: country,
            formKey: 'country',
            required: this.isRequired,
          },
          {
            label: bitfToTranslate('COMMON.LABEL.PHONE'),
            value: phone,
            formKey: 'phone',
            required: false,
          },
        ];
      }
    }
  }

  ngOnInit(): void {}

  onUpdateAddress(address: Address) {
    this.updateAddress.emit(address);
  }
}
