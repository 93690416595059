import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export enum EPickupInStoreStatus {
  TO_BE_RECEIVED = 'to_be_received',
  TO_BE_DELIVERED = 'to_be_delivered',
  TO_BE_RETURNED = 'to_be_returned',
  DELIVERED = 'delivered',
  RETURNED = 'returned',
}

bitfToTranslate('PICKUP_IN_STORE.STATUS.TO_BE_RECEIVED');
bitfToTranslate('PICKUP_IN_STORE.STATUS.TO_BE_DELIVERED');
bitfToTranslate('PICKUP_IN_STORE.STATUS.TO_BE_RETURNED');
bitfToTranslate('PICKUP_IN_STORE.STATUS.DELIVERED');
bitfToTranslate('PICKUP_IN_STORE.STATUS.RETURNED');
