import { SuperModel } from './super-model.model';
import { ECurrency, EFulfillmentStatus, EOrderStatus, EPaymentStatus } from '@common/enums';

import { ICustomer, ITaxLine } from '@interfaces';
import { Address, OrderItem } from '@models';

export class Order extends SuperModel {
  orderNumber: number;
  orderName: string;
  srcName: string;
  srcUrl: string;
  note: string;
  tags: string[];
  test: boolean;
  totalWeight: number;
  browserIp: string;
  userAgent: string;
  shippingAddress: Address;
  billingAddress: Address;
  customer: ICustomer;
  orderStatus: EOrderStatus;
  paymentStatus: EPaymentStatus;
  fulfillmentStatus: EFulfillmentStatus;
  cancelReason: string;
  cancelledAt: Date;
  fulfilledAt: Date;
  orderItems: OrderItem[];
  taxExempt: boolean;
  taxTotal: number;
  taxesIncluded: boolean;
  taxLines: ITaxLine[];
  currencyCode: string;
  totalLineItemsPrice: number;
  subTotalPrice: number;
  totalAdditionalFees: number;
  totalAdditionalDuties: number;
  totalShippingPrice: number;
  totalPrice: number;
  discountsLines: string[];
  discountsApplications: string[];
  pickupInStore: boolean;
  pickupStore: string;
  closedAt: Date;
  processedAt: Date;
  metadata: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  embed: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  computedFields: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };

  constructor(data: Partial<Order> = {}) {
    super(data);

    if (data.orderItems) {
      this.orderItems = data.orderItems.map(item => new OrderItem(item));
    }
    // if (data.returns) {
    //   this.returns = data.returns.map(returnOrder => new ReturnOrder(returnOrder));
    // }
    if (!data.currencyCode) {
      this.currencyCode = ECurrency.EUR;
    }
  }

  get customerName() {
    return `${this.customer.firstName || ''} ${this.customer.lastName || ''}`;
  }

  get orderCustomerName() {
    return `${this.customer.firstName || ''} ${this.customer.lastName || ''}`;
  }

  get hasInvoiceAddress() {
    return !!this.billingAddress;
  }

  get invoiceAddress(): Address {
    return this.billingAddress;
  }

  get hasDeliveryAddress() {
    return !!this.shippingAddress;
  }

  get deliveryAddress(): Address {
    return this.shippingAddress;
  }

  get serialised() {
    return {
      orderStatus: this.orderStatus,
    };
  }

  get paymentState() {
    return this.paymentState ? this.paymentState : 'MISSING';
  }
}
