import { EPickupInStoreStatus } from '@common/enums';
import { SuperModel } from './super-model.model';
import { WorkflowStatus } from './workflow.model';

import { IPickupInStoreItems } from './pickup-in-store.interface';

export class PickUpInStore extends SuperModel {
  id: string;
  orderNumber: string;
  orderId: string;
  pickupNumber: string;
  storeCode: string;
  storeName: string;
  status: WorkflowStatus;
  paymentStatus: string;
  customerFullName: string;
  customerEmail: string;
  customerLocale: string;
  items: IPickupInStoreItems[];
  pickupMailSentDate: Date | string;
  pickupMailReminderDate: Date | string;
  isPickupNotCollected: true;
  metadata: any;
  embed: any;
  computedFields: any;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      orderNumber: this.orderNumber,
      orderId: this.orderId,
      pickupNumber: this.pickupNumber,
      storeCode: this.storeCode,
      storeName: this.storeName,
      status: this.status,
      paymentStatus: this.paymentStatus,
      customerFullName: this.customerFullName,
      customerLocale: this.customerLocale,
      items: this.items,
      pickupMailSentDate: this.pickupMailSentDate,
      pickupMailReminderDate: this.pickupMailReminderDate,
      isPickupNotCollected: this.isPickupNotCollected,
      createdOn: this.createdOn,
      metadata: this.metadata,
      embed: this.embed,
      computedFields: this.computedFields,
    };
  }
}
