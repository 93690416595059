import { IBitfApiCallState } from '@interfaces';
import { BitfApiCallStateMapper } from '@bitf/core/api-call-state/bitf-api-call-state.mapper';
import { EBitfApiRequestPartsNames } from '@enums';

export class BitfNetApiCallStateMapper extends BitfApiCallStateMapper {
  buildApiRequest(apiCallState: IBitfApiCallState): void {
    this.assignApiRequestValuesAsArray(apiCallState, EBitfApiRequestPartsNames.QUERY);

    this.assignApiRequestValuesAsArray(apiCallState, EBitfApiRequestPartsNames.SORTING);

    this.assignApiRequestValuesAsObject(apiCallState, EBitfApiRequestPartsNames.QUERY_PARAMS);

    this.assignApiRequestValuesAsObject(apiCallState, EBitfApiRequestPartsNames.SEARCH);
  }
}
