<div [class.row]="!fullWidth"
  class="table-container">
  <table mat-table
    [dataSource]="_dataSource"
    [style]="{maxWidth}"
    [class]="tableClass"
    matSort>

    <ng-container *ngFor="let column of displayedColumns"
      matColumnDef="{{column.property}}">

      <ng-container *ngIf="showHeader">
        <!-- Header -->
        <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="{{column.classHeader}}">
          {{column.title}}
        </th>
      </ng-container>

      <!-- Cell -->
      <td mat-cell
        *matCellDef="let item"
        class="{{column.classRow}}">

        <ng-container *ngIf="column.template"
          [ngTemplateOutlet]="column.template"
          [ngTemplateOutletContext]="{item:item}"></ng-container>

        <span *ngIf="!column.template">
          {{item[column.property]}}
        </span>
      </td>

    </ng-container>

    <ng-container *ngIf="showSelect" matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="center-checkbox">
        <mat-checkbox *ngIf="!areAllPacked()" (change)="toggleSelectAll($event)" [checked]="areAllSelected()"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let item" class="center-checkbox">
        <mat-checkbox *ngIf="!item.isPacked" (click)="$event.stopPropagation()" (change)="toggleSelect(item)" [checked]="selectedRows.isSelected(item)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container *ngIf="showHeader">
      <tr mat-header-row
          *matHeaderRowDef="columnIdentifiers"></tr>
    </ng-container>

    <tr mat-row [ngClass]="{'not-selectable': row.isPacked}"
      *matRowDef="let row; columns: columnIdentifiers;"></tr>

  </table>
</div>