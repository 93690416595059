import { SuperModel } from './super-model.model';

export class OrderHistory extends SuperModel {
  date: string;
  description: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
