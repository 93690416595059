import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCallStateService, StoreService } from '@services';

@Component({
  selector: 'soh-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() showBackButton = false;

  @Output() back: EventEmitter<void> = new EventEmitter();

  constructor(public storeService: StoreService, private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {}
}
