import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StockService extends BitfApiService {
  private closeDialogSubject = new Subject<void>();
  closeDialog$ = this.closeDialogSubject.asObservable();

  constructor(public injector: Injector) {
    super('stk/stock', injector);
  }

  closeDialog() {
    this.closeDialogSubject.next();
  }
}
