<div class="mb-1"
  *ngIf="address.company">{{address.company}}
</div>

<div class="mb-1"
  *ngIf="address.firstName">{{address.firstName}} {{address.lastName || ''}}
</div>

<div class="mb-1"
  *ngIf="address.street || address.city || address.postcode">
  {{address.street}}, {{address.city}} {{address.postcode}}
</div>

<div *ngIf="address.province  || address.country">
  {{address.province}} - {{address.country}}
</div>

<div class="address-link">
  <a color="primary"
    mat-button
    href="https://www.google.com/maps/dir/?api=1&destination={{address.street}},{{address.postcode}}"
    target="_blank">
    {{'COMMON.LABEL.MAP'| translate}}
    <mat-icon>ios_share</mat-icon>
  </a>
</div>