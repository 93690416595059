import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISimpleListItem } from './simple-list.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'soh-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent implements OnInit {
  @Input() items: ISimpleListItem[];
  @Input() title: string;
  @Input() className = '';
  @Input() isEditable: boolean;
  @Output() update = new EventEmitter<any>();
  form = new FormGroup({});
  isInEditMode = false;

  constructor() {}

  ngOnInit(): void {
    if (this.isEditable) {
      this.initForm();
    }
  }

  initForm() {
    this.form = new FormGroup({});
    this.items.forEach(item => {
      this.form.addControl(
        item.formKey,
        new FormControl(item.value, item.required ? [Validators.required] : null)
      );
    });
  }

  edit() {
    this.isInEditMode = true;
    this.initForm();
  }
  onCancel() {
    this.isInEditMode = false;
  }

  onUpdate() {
    this.isInEditMode = false;
    this.update.emit(this.form.value);
  }
}
