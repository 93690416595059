import { EUsersRole } from '@common/enums/users-role.enum';
import { SuperModel } from './super-model.model';
import { User } from './user.model';
import { Warehouse } from './warehouse.model';

export class Shop extends SuperModel {
  id: string;
  storeCode: string;
  storeName: string;
  phone: string;
  phoneInternationalPrefix: string;
  email: string;
  isPickupInStore: boolean;
  isReturnInStore: boolean;
  isShipFromStore: boolean;
  gspedId: string;
  street: string;
  zip: number;
  city: string;
  province: string;
  country: string;
  countryCode: string;
  mapLongitude: number;
  mapLatitude: number;
  description: string;
  webSite: string;
  embed: any;
  computedFields: any;
  users: Array<User>;
  warehouses: Warehouse[];

  constructor(data: Partial<Shop> = {}) {
    super(data);
    if (this.users && this.users.length) {
      this.users = this.users.map(user => new User(user));
    }
    if (this.warehouses && this.warehouses.length) {
      this.warehouses = this.warehouses.map(warehouse => new Warehouse(warehouse));
    }
  }

  getPrintableUser(numberOfVisibleUsers: number): any {
    const eUsersRole = EUsersRole.STORE_MANAGER;
    const lastPositionOfUsers = numberOfVisibleUsers - 1;
    let listUsers: User[] = this.users;
    listUsers = listUsers.sort((a, b) => {
      if (a.role === eUsersRole && a.role !== b.role) return -1;
    });
    const mappedUsers = listUsers.slice(0, numberOfVisibleUsers).map(user => ({
      name: user.fullName
        .split(' ')
        .map(name => name.substring(0, 1))
        .join(''),
      role: user.role,
    }));
    if (listUsers.length >= numberOfVisibleUsers) {
      mappedUsers[lastPositionOfUsers].name = listUsers.length - lastPositionOfUsers + '+';
    }
    return mappedUsers;
  }

  getFullAddress() {
    return [this.street, this.province, this.city, this.country].filter(Boolean).join(', ');
  }

  getWarehouses() {
    return this.warehouses;
  }

  getStoreManagers() {
    return this.users.filter(user => user.role === EUsersRole.STORE_MANAGER);
  }

  getStoreAssistants() {
    return this.users.filter(user => user.role === EUsersRole.STORE_ASSISTANT);
  }

  get serialised() {
    return {
      id: this.id,
      storeCode: this.storeCode,
      storeName: this.storeName,
      phone: this.phone,
      phoneInternationalPrefix: this.phoneInternationalPrefix,
      email: this.email,
      isPickupInStore: this.isPickupInStore,
      isReturnInStore: this.isReturnInStore,
      isShipFromStore: this.isShipFromStore,
      gspedId: this.gspedId,
      street: this.street,
      zip: this.zip,
      city: this.city,
      province: this.province,
      country: this.country,
      countryCode: this.countryCode,
      mapLongitude: this.mapLongitude,
      mapLatitude: this.mapLatitude,
      description: this.description,
      webSite: this.webSite,
      embed: this.embed,
      computedFields: this.computedFields,
      users: this.users?.map(user => user.serialised),
      warehouses: this.warehouses?.map(warehouse => warehouse.serialised),
    };
  }
}
