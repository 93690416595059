<div class="d-flex justify-content-center align-items-center"
  *ngIf="isReadOnly && selectedStatusOption">
  <div class="px-2 rounded mat-caption text-center py-1 text-nowrap"
    [ngStyle]="{'color': selectedStatusOption.color?.text || 'black' ,'background-color': selectedStatusOption.color?.background || 'lightgrey'}">
    {{selectedStatusOption.label | translate}}
  </div>
</div>

<div [matMenuTriggerFor]="menu"
  *ngIf="!isReadOnly"
  class="cursor-pointer">
  <div *ngIf="selectedStatusOption"
    class="rounded-pill d-flex align-items-center mat-caption pr-2"
    [ngStyle]="{'color': selectedStatusOption.color?.text || 'black' ,'background-color': selectedStatusOption.color?.background || 'lightgrey'}">
    <mat-icon class="m-0 p-0 mr-1">arrow_drop_down</mat-icon>
    {{selectedStatusOption.label | translate}}
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item
    *ngFor="let option of nextStatusesOptions"
    (click)="onStatusChange(option)">
    <span class="py-1 px-2 rounded-pill mat-caption"
      [ngStyle]="{'color': option.color?.text || 'black' ,'background-color': option.color?.background || 'lightgrey'}">
      {{option.label | translate}}
    </span>
  </button>
</mat-menu>