<div class="d-flex align-items-center">
  <button mat-mini-fab
    class="btn-secondary-dark mr-3"
    *ngIf="showBackButton"
    (click)="back.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

  <h1 class="m-0">
    {{title | translate}}
  </h1>

  <ng-content select="[toolbar-left]"></ng-content>

  <span class="flex-grow-1"></span>

  <ng-content select="[toolbar-right]"></ng-content>
</div>