import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export enum EOrderStatus {
  NEW = 'NEW',
  COMMITTED = 'COMMITTED',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
  /*
  CLOSED = 'closed',
  IN_PROGRESS = 'in_progress',
  SHIPPED = 'shipped',
  PAID = 'paid',
  UNPAID = 'unpaid',
  PENDING = 'pending',
  ERROR = 'error',
  OUT_OF_STOCK = 'outofstock',
  */
}

export enum EPaymentStatus {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  UNPAID = 'UNPAID',
}

export enum EFulfillmentStatus {
  FULFILLED = 'FULFILLED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  OPEN = 'OPEN',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  UNFULFILLED = 'UNFULFILLED',
  SCHEDULED = 'SCHEDULED',
  PARTIALLY_SCHEDULED = 'PARTIALLY_SCHEDULED',
}

export enum EFulfillmentShipmentStatus {
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  LOST = 'LOST',
  NOT_STARTED = 'NOT_STARTED',
  DELIVERED = 'DELIVERED',
}

export enum EFulfillmentTags {
  REASSIGNED = 'REASSIGNED',
  OVERDUE = 'OVERDUE',
  SLOW = 'SLOW',
}

bitfToTranslate(`COMMON.STATUSES.NEW`);
bitfToTranslate(`COMMON.STATUSES.CLOSED`);
bitfToTranslate(`COMMON.STATUSES.COMPLETED`);
bitfToTranslate(`COMMON.STATUSES.IN_PROGRESS`);
bitfToTranslate(`COMMON.STATUSES.SHIPPED`);
bitfToTranslate(`COMMON.STATUSES.COMMITTED`);
bitfToTranslate(`COMMON.STATUSES.PAID`);
bitfToTranslate(`COMMON.STATUSES.UNPAID`);
bitfToTranslate(`COMMON.STATUSES.PENDING`);
bitfToTranslate(`COMMON.STATUSES.ERROR`);
bitfToTranslate(`COMMON.STATUSES.OUTOFSTOCK`);
bitfToTranslate(`COMMON.STATUSES.PROCESSING`);
bitfToTranslate(`COMMON.STATUSES.COMPLETE`);
bitfToTranslate(`COMMON.STATUSES.CANCELED`);

bitfToTranslate(`COMMON.STATUSES.AUTHORIZED`);
bitfToTranslate(`COMMON.STATUSES.REFUNDED`);
bitfToTranslate(`COMMON.STATUSES.PARTIALLY_REFUNDED`);
bitfToTranslate(`COMMON.STATUSES.PARTIALLY_PAID`);
bitfToTranslate(`COMMON.STATUSES.FULFILLED`);
bitfToTranslate(`COMMON.STATUSES.ON_HOLD`);
bitfToTranslate(`COMMON.STATUSES.OPEN`);
bitfToTranslate(`COMMON.STATUSES.PARTIALLY_FULFILLED`);
bitfToTranslate(`COMMON.STATUSES.UNFULFILLED`);
bitfToTranslate(`COMMON.STATUSES.SCHEDULED`);
bitfToTranslate(`COMMON.STATUSES.PARTIALLY_SCHEDULED`);

bitfToTranslate(`COMMON.STATUSES.AWAITING_PICKUP`);
bitfToTranslate(`COMMON.STATUSES.DISPATCHED`);
bitfToTranslate(`COMMON.STATUSES.IN_TRANSIT`);
bitfToTranslate(`COMMON.STATUSES.LOST`);
bitfToTranslate(`COMMON.STATUSES.NOT_STARTED`);
bitfToTranslate(`COMMON.STATUSES.DELIVERED`);

bitfToTranslate(`COMMON.STATUSES.REASSIGNED`);
bitfToTranslate(`COMMON.STATUSES.OVERDUE`);
bitfToTranslate(`COMMON.STATUSES.SLOW`);

export enum EReturnStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DENY = 'deny',
  RETURNEDINSTORE = 'returnedInStore',
  SENTTOLOGISTIC = 'sentToLogistic',
  RETURNED = 'returned',
  CLOSED = 'closed',
}
bitfToTranslate(`COMMON.STATUSES.PENDING`);
bitfToTranslate(`COMMON.STATUSES.ACCEPTED`);
bitfToTranslate(`COMMON.STATUSES.DENY`);
bitfToTranslate(`COMMON.STATUSES.RETURNED`);
bitfToTranslate(`COMMON.STATUSES.RETURNEDINSTORE`);
bitfToTranslate(`COMMON.STATUSES.SENTTOLOGISTIC`);

export enum ETicketStatus {
  NEW = 'new',
  IN_PROGRESS = 'pending',
  WAITING_FOR_DELIVERY = 'waiting',
  READY_FOR_SHIPMENT = 'shipment_ready',
  PACK_PREPARED = 'pack_prepared',
  SHIPMENT_REQUESTED = 'shipment_requested',
  GOODS_RECEIVED = 'received',
  READY_FOR_THE_CUSTOMER = 'ready',
  PACK_DELIVERED = 'delivered',
  SHIPPED = 'shipped',
  ERROR = 'error',
  CANCELED = 'voided',
}

bitfToTranslate(`COMMON.STATUSES.WAITING`);
bitfToTranslate(`COMMON.STATUSES.SHIPMENT_READY`);
bitfToTranslate(`COMMON.STATUSES.PACK_PREPARED`);
bitfToTranslate(`COMMON.STATUSES.SHIPMENT_REQUESTED`);
bitfToTranslate(`COMMON.STATUSES.RECEIVED`);
bitfToTranslate(`COMMON.STATUSES.READY`);
bitfToTranslate(`COMMON.STATUSES.DELIVERED`);
bitfToTranslate(`COMMON.STATUSES.VOIDED`);

export enum ETicketItemStatus {
  NEW = 'new',
  ERROR = 'error',
  CANCELED = 'voided',
  PRODUCT_UNAVAILABLE = 'unavailable',
  PRODUCT_AVAILABLE = 'available',
  RESERVED_GOODS = 'reserved',
  PACK_RECEIVED = 'received',
  SHIPMENT_REQUESTED = 'shipment_requested',
  PACK_SHIPPED_TO_CUSTOMER = 'customer_shipped',
  PACK_SHIPPED = 'shipped',
  PACK_READY = 'ready',
  DELIVERED_TO_THE_CUSTOMER = 'delivered',
  PICKING_PRESENT = 'picking_present',
  PICKING_MISSING = 'picking_missing',
  PACKING_MISSING = 'packing_missing',
}
bitfToTranslate(`COMMON.STATUSES.AVAILABLE`);
bitfToTranslate(`COMMON.STATUSES.UNAVAILABLE`);
bitfToTranslate(`COMMON.STATUSES.RESERVED`);
bitfToTranslate(`COMMON.STATUSES.CUSTOMER_SHIPPED`);
bitfToTranslate(`COMMON.STATUSES.PICKING_MISSING`);
bitfToTranslate(`COMMON.STATUSES.PACKING_MISSING`);

export type TStatus = typeof EOrderStatus | typeof EReturnStatus | typeof ETicketStatus;

export enum EStatusColor {
  GREY = 'grey',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  ORANGE = 'orange',
}

bitfToTranslate('ORDER_PAYMENT.LABEL.MANUAL');
bitfToTranslate('ORDER_PAYMENT.LABEL.COMMITTED');
bitfToTranslate('ORDER_PAYMENT.LABEL.BOGUS');
bitfToTranslate('ORDER_PAYMENT.LABEL.GIFT_CARD');
bitfToTranslate('ORDER_PAYMENT.LABEL.PENDING');
bitfToTranslate('ORDER_PAYMENT.LABEL.PAID');
