import { EPickupInStoreStatus } from '@common/enums';
import { SuperModel } from './super-model.model';

export interface WorkflowStatus {
  id: string;
  statusLabel: string;
  color: string;
  histories: [
    {
      description: string;
      date: string;
    }
  ];
}

export interface WorkflowAction {
  id: string;
  label: string;
  color: string;
}

export class Workflow extends SuperModel {
  label: string;
  definitions: WorkflowAction[];
  transitions: {
    from: string;
    to: string[];
  }[];
  embed: any;
  computedFields: any;

  constructor(data: Partial<Workflow> = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }

  getNextActions(currentAction: string): WorkflowAction[] {
    const nextTransitionsIds = this.transitions.find(transition => transition.from === currentAction)?.to;
    if (nextTransitionsIds) {
      return nextTransitionsIds.map(nextTransitionId =>
        this.definitions.find(place => place.id === nextTransitionId)
      );
    }
    return [];
  }
}
