import { ECurrency } from '@common/enums';
import { SuperModel } from './super-model.model';

export class OrderItem extends SuperModel {
  id: string;
  sku: string;
  imageUrl: string;
  bundleCode: string;
  name: string;
  requiresShipping: true;
  vendor: string;
  giftCard: true;
  metadata: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  currencyCode: ECurrency;
  taxable: true;
  unitAmount: number;
  unitAmountExclVat: number;
  unitCompareAtAmount: number;
  totalDiscount: number;
  totalAmountNet: number;
  totalAmount: number;
  totalAmountExclVat: number;
  totalTaxAmount: number;
  taxRate: number;
  taxBreakdown: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  unitWeight: number;
  duties: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  discountAllocations: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  qtyOrdered: number;
  qtyConfirmed: number;
  qtyPayed: number;
  qtyShipped: number;
  qtyCanceled: number;
  qtyRefunded: number;

  constructor(data: any = {}) {
    super(data);

    if (!data.currencyCode) {
      this.currencyCode = ECurrency.EUR;
    }
  }
  get serialised() {
    return {};
  }
}
