import { SuperModel } from './super-model.model';
import { WorkDays } from '@common/core/models/work-days.model';

export class Warehouse extends SuperModel {
  id: string;
  isEnabled: boolean = true;
  code: string;
  name: string;
  priority: number;
  street: string;
  city: string;
  postCode: number;
  province: string;
  country: string;
  countryCode: string;
  phoneInternationalPrefix: string;
  phone: string;
  email: string;
  longitude: number;
  latitude: number;
  costs: object;
  workDays: object;
  storeId: string;
  storeCode: string;
  storeName: string;

  constructor(data: Partial<Warehouse> = {}) {
    super(data);
    if (data.costs) {
      this.costs = new Costs(data.costs);
    }
    if (data.workDays) {
      this.workDays = new WorkDays(data.workDays);
    }
  }

  getFullAddress() {
    return [this.street, this.province, this.city, this.country].filter(Boolean).join(', ');
  }

  public getName() {
    return this.name;
  }

  get serialised() {
    return {
      id: this.id,
      isEnabled: this.isEnabled,
      code: this.code,
      name: this.name,
      priority: this.priority,
      street: this.street,
      city: this.city,
      postCode: this.postCode,
      province: this.province,
      country: this.country,
      countryCode: this.countryCode,
      phoneInternationalPrefix: this.phoneInternationalPrefix,
      phone: this.phone,
      email: this.email,
      longitude: this.longitude,
      latitude: this.latitude,
      costs: this.costs,
      workDays: this.workDays,
      storeId: this.storeId,
      storeCode: this.storeCode,
    };
  }
}

export class Costs extends SuperModel {
  currencyCode: string;
  carbonFootPrint: CostAttribute;
  packingCost: CostAttribute;

  constructor(data: Partial<Costs> = {}) {
    super(data);
  }

  get serialised() {
    return {
      currencyCode: this.currencyCode,
    };
  }
}

export class CostAttribute extends SuperModel {
  enable: boolean;
  cost: number;
  metric: string;

  constructor(data: Partial<CostAttribute> = {}) {
    super(data);
  }

  get serialised() {
    return {
      enable: this.enable,
      cost: this.cost,
      metric: this.metric,
    };
  }
}
