const baseApiUrl = 'https://omscluster.sintraoms-test.com';
const apiUrl = `${baseApiUrl}/api/`;
const tenant = `sintra-dev`;

export const environment = {
  production: false,
  name: 'staging',
  appName: 'OMS - Order Management System',
  storageVersion: '1.0.0',
  baseApiUrl,
  apiUrl,
  tenant,
  authInterceptorUrls: [apiUrl],
  mockApiUrl: 'https://localhost:3002/',
  sockets: { url: '' },
  tokenExpirationTimeLimit: 600000, // 10 minutes
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  graphQlConfig: {
    enabled: false,
    requestTimeout: 10000,
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: true,
    sendRequestBody: true,
    sendQueryParams: true,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: false,
  httpPassword: 'SOHub4Demo',
  mainLogoUrl: '',
  customerLogoUrl: '/assets/logo/omslogo-small.png',
  apiProperties: {
    size: 'size',
    sizeQuery: 'size {... on object_ProductSize {code}}',
    color: 'color',
    colorQuery: 'color {... on object_Color {name}}',
  },
  returns: {
    enableReturnedStatusButton: false,
    isModuleEnabled: true,
  },
  clickCollect: {
    showClickCollectCode: true,
    isModuleEnabled: true,
  },
  shipFromStore: {
    isModuleEnabled: true,
  },
  orders: {
    isModuleEnabled: true,
  },
  shop: {
    isModuleEnabled: true,
  },
  fulfillments: {
    isModuleEnabled: true,
  },
  warehouse: {
    isModuleEnabled: true,
  },
  salesChannel: {
    isModuleEnabled: true,
  },
  settings: {
    isModuleEnabled: true,
  },
};
