import { Component, EventEmitter, Output } from '@angular/core';
import { getEnabledNavMenu } from '@common/constants';

@Component({
  selector: 'soh-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrl: './vertical-menu.component.scss',
})
export class VerticalMenuComponent {
  @Output() clickItem: EventEmitter<void> = new EventEmitter();
  navigationMenu = getEnabledNavMenu();
}
