import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BitfOAuthInterceptor } from '@bitf/interceptors/bitf-oauth-interceptor.service';
import { BitfRetryInterceptor } from '@bitf/interceptors/bitf-retry-interceptor.service';
import { BitfApiUiMessagesInterceptor } from '@bitf/interceptors/bitf-api-ui-messages-interceptor.service';
import { BitfLanguageInterceptor } from '@bitf/interceptors/bitf-language-interceptor.service';
import { BitfApiErrorsInterceptor } from '@bitf/interceptors/bitf-api-errors-interceptor.service';
// eslint-disable-next-line max-len
import { BitfApiEnvelopeMapperInterceptor } from '@bitf/interceptors/bitf-api-envelope-mapper-interceptor.service';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpBatchLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

import { APP_VERSION } from '@env/version';
import { TenantInterceptor } from './interceptors/tenant.interceptor';

const EXPORTS_COMPONENTS = [];
const COMPONENTS = [];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${APP_VERSION}`);
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApolloModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfOAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfLanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiUiMessagesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiEnvelopeMapperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfRetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: BitfErrorHandlerService },
  ],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  exports: [...EXPORTS_COMPONENTS],
})
export class CommonCoreModule {}
