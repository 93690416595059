import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Asset, OrderItem } from '@models';

@Component({
  selector: 'soh-product-list-extended',
  templateUrl: './product-list-extended.component.html',
  styleUrls: ['./product-list-extended.component.scss'],
})
export class ProductListExtendedComponent implements OnInit {
  @Input() orderItems: OrderItem[];
  @Input() totalPrice: number;
  @Input() excludedColumns: string[];
  @Input() tableClass = '';
  @Input() isExpanded = false;

  constructor() {}

  imagesUrl = environment.baseApiUrl;
  ngOnInit(): void {}
}
