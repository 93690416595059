import { Order } from '@models';
import { SuperModel } from './super-model.model';

import { EReturnStatus } from '@enums';
import { ReturnedItem } from './returned-item.model';
export class ReturnOrder extends SuperModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  zipCode: string;
  province: string;
  country: string;
  status: EReturnStatus;
  requestTime: string;
  refundType: string;
  refundTotal: number;
  closureDate: null;
  reason: string;
  order: Order;
  ordernumber: string;
  items: ReturnedItem[];
  creationDate: Date;
  origin: string;
  receivedInStoreAt: Date;
  shippedToLogisticAt: Date;
  deliveredInLogisticAt: Date;
  keptInStoreAt: Date;
  refundRequests: any[];

  constructor(data: Partial<ReturnOrder> = {}) {
    super(data);

    if (data.items) {
      this.items = data.items.map(item => new ReturnedItem(item as any));
    }
    if (data.order) {
      this.order = new Order(data.order);
    }

    if (data.refundRequests && data.refundRequests.length) {
      this.refundTotal = 0;
      data.refundRequests.forEach(refundRequest => {
        this.refundTotal += +refundRequest.totalAmount;
      });
    } else {
      this.refundTotal = undefined;
    }
  }

  get customerFullName() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  // get returnAddress(): IAddress {
  //   return {
  //     //name: this.firstName,
  //     company: this.lastName,
  //     street: 'missing street',
  //     city: this.city,
  //     postcode: this.zipCode,
  //     province: this.province,
  //     country: this.country,
  //   };
  // }

  get serialised() {
    return {
      id: this.id,
      status: this.status,
      refundType: this.refundType,
      reason: this.reason,
    };
  }
}
