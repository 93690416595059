import { EPickupInStoreStatus } from '@common/enums';
import { Order } from './order.model';
import { SuperModel } from './super-model.model';

export class PickupInStoreOrder extends SuperModel {
  orderNumber: string;
  clickAndCollectStore: any;
  status: EPickupInStoreStatus;
  receivedInStoreAt: Date | string;
  pickedAt: Date | string;
  movedToBeReturnedAt: Date | string;
  returnedBackToWarehouseAt: Date | string;
  verificationCustomerCode: string;
  order: Order;
  creationDate: Date;
  customerFullName: string;

  constructor(data: any = {}) {
    super(data);
    if (data.order) {
      this.order = new Order(data.order);
    }
  }

  get serialised() {
    return {};
  }
}
