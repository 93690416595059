/* eslint-disable max-len */
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

import { CommonSharedModule } from '@common/shared/common-shared.module';

import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfMatSortComponent } from '@bitf/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfMatSearchComponent } from '@bitf/core/components/ui/search/material/bitf-mat-search.component';
import { BitfUiRoleManagerDirective } from '@bitf/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@bitf/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatFormOptionComponent } from '@bitf/core/components/ui/form-item/material/mat-form-option/bitf-mat-form-option.component';
import { BitfFormItemContainerComponent } from '@bitf/core/components/ui/form-item/bitf-form-item-container/bitf-form-item-container.component';
import { BitfMatFormItemComponent } from '@bitf/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';
import { BitfMatFormMultipleCheckboxComponent } from '@bitf/core/components/ui/form-item/material/mat-form-multiple-checkbox/bitf-mat-form-multiple-checkbox.component';
import { BitfMatDateRangeInputComponent } from '@bitf/core/components/ui/date-range/material/mat-date-range-input/mat-date-range-input.component';
import { BitfMatLanguageSelectorComponent } from '@bitf/core/components/ui/language-selector/material/bitf-mat-language-selector.component';

import { StatusSelectorComponent } from './components/status-selector/status-selector.component';
import { TableToolbarComponent } from './components/table-toolbar/table-toolbar.component';
import { ListStateManagerComponent } from './components/list-state-manager/list-state-manager.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { ExpandablePanelComponent } from './components/expandable-panel/expandable-panel.component';
import { AddressComponent } from './components/address/address.component';
import { StatusViewerComponent } from './components/status-viewer/status-viewer.component';
import { MainLogoComponent } from './components/main-logo/main-logo.component';

import { DateAdapterService } from '@services';
import { PaymentStatusBadgeComponent } from './components/payment-status-badge/payment-status-badge.component';
import { ProductListExtendedComponent } from './reusable-components/product-list-extended/product-list-extended.component';
import { CustomerDetailsComponent } from './reusable-components/customer-details/customer-details.component';
import { SimpleListComponent } from './components/simple-list/simple-list.component';
import { LogoComponent } from './reusable-components/logo/logo.component';
import { MaterialIconProviderComponent } from './material-icon-provider/material-icon-provider.component';
import { FooterActionBarComponent } from './components/footer-action-bar/footer-action-bar.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { AddressDetailsComponent } from './reusable-components/address-details/address-details.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { WarehousesListComponent } from '@shared/components/warehouses-list/warehouses-list.component';
import { ReassignDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/reassignment/reassign-dialog.component';

/* eslint-enable max-len */

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfMatLoaderComponent,
  BitfMatPaginatorComponent,
  BitfMatSortComponent,
  BitfMatSearchComponent,
  BitfMatFormOptionComponent,
  BitfMatFormItemComponent,
  BitfFormItemContainerComponent,
  BitfMatFormMultipleCheckboxComponent,
  BitfMatDateRangeInputComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  ReassignDialogComponent,
  StatusSelectorComponent,
  TableToolbarComponent,
  ListStateManagerComponent,
  DynamicTableComponent,
  ExpandablePanelComponent,
  BitfMatLanguageSelectorComponent,
  PageHeaderComponent,
  AddressComponent,
  StatusViewerComponent,
  MainLogoComponent,
  PaymentStatusBadgeComponent,
  ProductListExtendedComponent,
  CustomerDetailsComponent,
  SimpleListComponent,
  LogoComponent,
  MaterialIconProviderComponent,
  FooterActionBarComponent,
  VerticalMenuComponent,
  AddressDetailsComponent,
  UsersListComponent,
];
const SHARED_DIRECTIVES = [];
const PROVIDERS = [DatePipe, { provide: DateAdapter, useClass: DateAdapterService }];

@NgModule({
  imports: [...SHARED_MODULES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, WarehousesListComponent],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, WarehousesListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
