import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import { StoreService } from '@services';

@Component({
  selector: 'soh-expandable-panel',
  templateUrl: './expandable-panel.component.html',
  styleUrls: ['./expandable-panel.component.scss'],
})
export class ExpandablePanelComponent implements OnInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() isExpanded = false;
  @Input() iconLeft?: string;

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {}
}
