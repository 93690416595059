import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BitfFormItemComponent } from '../../bitf-form-item.component';
import { BitfOption } from '@common/libs/bitforce/core/models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bitf-mat-form-multiple-checkbox',
  templateUrl: './bitf-mat-form-multiple-checkbox.component.html',
  styleUrls: ['./bitf-mat-form-multiple-checkbox.component.scss'],
})
export class BitfMatFormMultipleCheckboxComponent extends BitfFormItemComponent implements OnDestroy, OnInit {
  @Input() matFormFieldClass: string;

  private CHECK_ALL_OPTION_ID = 'CHECK_ALL_OPTION_ID';

  protected options: BitfOption[] = [];
  protected selectedOptionsIds: (string | number)[];

  protected subscription = new Subscription();

  ngOnInit(): void {
    super.ngOnInit();
    this.listenForControlChange();
  }

  initForItem() {
    super.initForItem();
    this.initOptions();
  }

  onOptionChange($event: MatCheckboxChange, optionId: string) {
    const changedOption = this.options.find(option => option.id === optionId);
    changedOption.checked = $event.checked;

    if (optionId === this.CHECK_ALL_OPTION_ID) {
      this.options.forEach(option => {
        option.checked = $event.checked;
      });
    }

    this.selectedOptionsIds = this.getSelectedOptionsIds();

    this.verifyCheckAllOptionState();

    this.formControl.patchValue(this.selectedOptionsIds);
    this.selectionChange.emit(this.selectedOptionsIds);
  }

  protected setDefault(): void {
    if (Array.isArray(this.formControl.value)) {
      return;
    }
    const defaultValue = Array.isArray(this.formItem.default) ? this.formItem.default : [];
    this.formControl.patchValue(defaultValue);
    this.initOptions();
  }

  private getSelectedOptionsIds() {
    return this.options
      .filter(option => option.checked && option.id !== this.CHECK_ALL_OPTION_ID)
      .map(option => option.id);
  }

  private initOptions() {
    const selectedValues = Array.isArray(this.formControl.value) ? this.formControl.value : [];
    this.options = (this.formItem.options || []).map(option => {
      return new BitfOption({ ...option, checked: selectedValues.includes(option.id) });
    });

    if (this.formItem.metaData.addCheckAll && this.formItem.metaData.checkAllLabel) {
      this.options.unshift(
        new BitfOption({ id: this.CHECK_ALL_OPTION_ID, label: this.formItem.metaData.checkAllLabel })
      );
    }
  }

  private listenForControlChange() {
    this.subscription.add(
      this.formControl.valueChanges.subscribe(values => {
        if (values === this.selectedOptionsIds) {
          return;
        }
        this.initOptions();
      })
    );
  }

  private verifyCheckAllOptionState() {
    const checkAllOption = this.options.find(option => option.id === this.CHECK_ALL_OPTION_ID);
    if (!checkAllOption) {
      return;
    }

    checkAllOption.checked = this.selectedOptionsIds.length === this.options.length - 1;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
