import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export enum EShipFromStoreStatus {
  NEW = 'new',
  PACKED = 'packed',
  SENT_TO_LOGISTIC = 'sent_to_logistic',
  CANCELED = 'canceled',
}

bitfToTranslate('SHIP_FROM_STORE.STATUS.NEW');
bitfToTranslate('SHIP_FROM_STORE.PACKED');
bitfToTranslate('SHIP_FROM_STORE.STATUS.SENT_TO_LOGISTIC');
bitfToTranslate('SHIP_FROM_STORE.STATUS.CANCELED');
