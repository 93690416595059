<mat-card class="p-3 w-100 h-100"
  *ngIf="!storeService.store.activeBreakpoints.isHandsetPortrait"
  [ngClass]="{'pt-0': titleTemplate, 'mb-3': storeService.store.activeBreakpoints.isHandsetLayout}">
  <mat-card-header class="d-flex justify-content-between p-0">
    <mat-card-title *ngIf="title"
      class="title d-flex">{{title}}
      <mat-icon aria-hidden="false"
        fontIcon="{{iconLeft}}"
        *ngIf="iconLeft"
        class="align-self-center">
      </mat-icon>
    </mat-card-title>

  </mat-card-header>
  <ng-container *ngIf="titleTemplate"
    [ngTemplateOutlet]="titleTemplate"></ng-container>

  <mat-divider></mat-divider>

  <mat-card-content class="p-0">
    <div class="col pt-1 pb-2"></div>
    <ng-container *ngTemplateOutlet="transcludedContent"></ng-container>
  </mat-card-content>
</mat-card>

<mat-expansion-panel *ngIf="storeService.store.activeBreakpoints.isHandsetPortrait"
  [expanded]="isExpanded"
  class="mb-3 w-100">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h2 class="m-0"
        *ngIf="title">{{title}}</h2>

      <ng-container *ngIf="titleTemplate"
        [ngTemplateOutlet]="titleTemplate"></ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container *ngTemplateOutlet="transcludedContent"></ng-container>
</mat-expansion-panel>

<ng-template #transcludedContent>
  <ng-content></ng-content>
</ng-template>