import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@common/core/models';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { StoreService } from '@common/core/services';

bitfToTranslate('USERS.ROLE.ADMIN');
bitfToTranslate('USERS.ROLE.BACKOFFICE');
bitfToTranslate('USERS.ROLE.STORE_MANAGER');
bitfToTranslate('USERS.ROLE.STORE_ASSISTANT');
bitfToTranslate('USERS.ROLE.SUPERADMIN');

@Component({
  selector: 'soh-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
})
export class UsersListComponent {
  @Input() users: User[];
  @Input() showRemoveButton = false;
  @Input() showChangeRoleButton = false;

  @Output() changeRole: EventEmitter<User> = new EventEmitter();
  @Output() removeUser: EventEmitter<User> = new EventEmitter();

  constructor(public storeService: StoreService) {}

  onRemoveUser(user: User) {
    this.removeUser.emit(user);
  }
  onChangeRole(user: User) {
    this.changeRole.emit(user);
  }
}
