<mat-accordion [multi]="true"
  class="w-100">

  <ng-container *ngFor="let item of navigationMenu; let index = index">
    <div *ngIf="!item.items">
      <div class="sidebar-item"
        #linkActive="routerLinkActive"
        [ngClass]="{'active': linkActive.isActive}"
        routerLinkActive>

        <a mat-fab
          color="primary"
          extended
          (click)="clickItem.emit()"
          [routerLink]="item.routerLink">
          <mat-icon class="mr-3"
            [svgIcon]="item.svgIcon || undefined"
            [style]="{width: item.iconSize || '24px',height:item.iconSize || '24px'}">{{item.icon}}</mat-icon>

          {{item.title | translate}}
        </a>
      </div>
    </div>

    <mat-expansion-panel *ngIf="item.items"
      [expanded]="true">
      <mat-expansion-panel-header class="px-3">
        <mat-panel-title>
          <mat-icon class="ml-1 mr-3"
            [svgIcon]="item.svgIcon || undefined"
            [style]="{width: item.iconSize || '24px',height:item.iconSize || '24px'}">{{item.icon}}</mat-icon>

          {{item.title | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="item.items">
        <div *ngFor="let subItem of item.items; let index = index"
          class="sidebar-item"
          [ngClass]="{'active': linkActive.isActive}"
          [routerLink]="subItem.routerLink"
          routerLinkActive
          #linkActive="routerLinkActive">

          <a mat-fab
            color="primary"
            extended
            (click)="clickItem.emit();">
            <span class="sidebar-subitem-dot">.</span>{{subItem.title | translate}}
          </a>
        </div>
      </div>
    </mat-expansion-panel>
  </ng-container>

</mat-accordion>