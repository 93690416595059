import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import {
  EBitfApiRequestPartsNames,
  EApiRequestPartKeys,
  EBitfPQueryComparators,
  EBitfPQueryOperator,
} from '@enums';
import { IBitfPartData } from '@interfaces';

export class BitfNetFormItemConfigApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.QUERY;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    const queryItems = [];
    if (this.formValue) {
      Object.entries(this.formValue)
        .filter(([_, value]) => {
          if (Array.isArray(value)) {
            return value.length;
          }
          return value !== null && value !== '' && value !== undefined;
        })
        .forEach(([key, value]: [string, any]) => {
          let mappedValue = value;
          console.log(key, value);
          if (Array.isArray(value) && value.length) {
            let valueString = "['";
            value.forEach((item: any, idx: number) => {
              valueString += item + "'";
              if (idx < value.length - 1) {
                valueString += ",'";
              }
            });
            mappedValue = valueString + `]`;
          }

          queryItems.push({
            name: this.mapName(key),
            value: mappedValue,
            comparator:
              bitfGetProp(this.data, 'formItemsConfig', key, 'comparator') || EBitfPQueryComparators.EQUAL,
            operator: bitfGetProp(this.data, 'formItemsConfig', key, 'operator') || EBitfPQueryOperator.AND,
          });
        });
    }
    this.part = queryItems;
  }

  private mapName(name: string) {
    const mapName = bitfGetProp(this.data, 'formItemsConfig', name, 'mapName');
    return mapName || name;
  }
}
