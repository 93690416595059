import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { BitfTextToSpeechService } from '@common/libs/bitforce/core/services/text-to-speech/bitf-text-to-speech.service';
import { TranslateService } from '@ngx-translate/core';

import { IStatusOption } from '@interfaces';
import { CONSTANTS } from '@constants';
import { EStatusColor, TStatus } from '@enums';

@Component({
  selector: 'soh-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusSelectorComponent implements OnInit, OnChanges {
  @Input() statuses: { TStatus: EStatusColor };
  @Input() isReadOnly = false;
  @Input() selectedStatus: TStatus;
  @Input() statusesRules?: { TStatus: TStatus[] };
  @Output() changed: EventEmitter<TStatus> = new EventEmitter<TStatus>();

  protected emptyStatus = {
    id: null,
    label: this.translateService.instant('COMMON.LABEL.EDIT_STATUS'),
    color: CONSTANTS.COLORS[EStatusColor.GREY],
  };

  statusesOptions: IStatusOption[];
  nextStatusesOptions: IStatusOption[];
  selectedStatusOption: IStatusOption;

  constructor(
    private translateService: TranslateService,
    private bitfTextToSpeechService: BitfTextToSpeechService
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.emptyStatus.label = this.translateService.instant('COMMON.LABEL.EDIT_STATUS');
    });

    if (!this.selectedStatus) {
      this.selectedStatusOption = this.emptyStatus;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.statuses?.currentValue && !this.statusesOptions) {
      this.buildStatusesOptions();
    }

    if (changes?.selectedStatus?.currentValue) {
      this.selectedStatusOption =
        this.statusesOptions?.find(option => option.id === this.selectedStatus) || this.emptyStatus;
      if (!this.isReadOnly) {
        this.buildNextStatusesOptions();
      }
    }
  }

  onStatusChange(option: IStatusOption) {
    // this.bitfTextToSpeechService.speech(this.translateService.instant(option.label));
    this.changed.emit(option.id as TStatus);
  }

  private buildStatusesOptions() {
    this.statusesOptions = Object.keys(this.statuses).map(statusKey => this.buildOptions(statusKey));
  }

  private buildNextStatusesOptions() {
    if (this.statusesRules) {
      const validStates = {};
      this.statusesRules[this.selectedStatusOption.id as any].forEach(state => {
        validStates[state] = this.statuses[state];
      });
      this.nextStatusesOptions = Object.keys(validStates).map(statusKey => this.buildOptions(statusKey));
    } else {
      this.nextStatusesOptions = this.statusesOptions;
    }
    if (this.nextStatusesOptions.length === 0) {
      this.isReadOnly = true;
    }
  }

  private buildOptions(statusKey) {
    return {
      id: statusKey as any as TStatus,
      label: 'COMMON.STATUSES.' + statusKey.toUpperCase(),
      color: CONSTANTS.COLORS[this.statuses[statusKey]],
    };
  }
}
