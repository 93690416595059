import { SuperModel } from './super-model.model';
import { ESalesChannelType } from '@common/enums/sales-channel-type.enum';

export class SalesChannel extends SuperModel {
  public id: string;
  public type: ESalesChannelType;
  public code: string;
  public orderPrefix: string;
  public orderSuffix: string;
  public configuration: object;
  public enabled: boolean;
  public receiveOrderEnabled: boolean;
  public receiveReturnEnabled: boolean;
  public receiveCustomerEnabled: boolean;
  public sendFulfillmentEnabled: boolean;
  public sendRefundEnabled: boolean;

  constructor(data: Partial<SalesChannel> = {}) {
    super(data);
    if (this.type === ESalesChannelType.SHOPIFY) {
      this.configuration = new ShopifyConfiguration(data.configuration);
    }
  }

  get shopifyConfig(): ShopifyConfiguration {
    return this.configuration as ShopifyConfiguration;
  }

  get serialised() {
    return {
      type: this.type,
      code: this.code,
      orderPrefix: this.orderPrefix,
      orderSuffix: this.orderSuffix,
      configuration: this.configuration,
      enabled: this.enabled,
      receiveOrderEnabled: this.receiveOrderEnabled,
      receiveReturnEnabled: this.receiveReturnEnabled,
      receiveCustomerEnabled: this.receiveCustomerEnabled,
      sendFulfillmentEnabled: this.sendFulfillmentEnabled,
      sendRefundEnabled: this.sendRefundEnabled,
    };
  }
}

export class ShopifyConfiguration extends SuperModel {
  public apiUrl: string;
  public apiKey: string;
  public apiSecretKey: string;
  public accessToken: string;

  constructor(data: Partial<ShopifyConfiguration> = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
