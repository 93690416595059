<form *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="onOk()">
  <ng-container *ngTemplateOutlet="okCancelDialogTemplate;context:{dialogData, form}">
  </ng-container>
</form>

<ng-container *ngIf="!form">
  <ng-container *ngTemplateOutlet="okCancelDialogTemplate;context:{dialogData}">
  </ng-container>
</ng-container>


<ng-template #okCancelDialogTemplate
  let-dialogData="dialogData"
  let-form="form">
  <h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
  <mat-dialog-content>
    <span *ngIf="dialogData.message"
      class="message"
      [innerHTML]="dialogData.message | translate">
    </span>

    <div *ngIf="form" [formGroup]="form"
      class="mt-4">
      <mat-table #table [dataSource]="dialogData.items">

        <ng-container matColumnDef="image">
          <mat-cell *matCellDef="let element" class="col-3">
            <ng-container *ngIf="element.imageUrl; else withoutImage">
              <img class="product-image"
                   [src]="element.imageUrl" />
            </ng-container>
            <ng-template #withoutImage>
              <mat-icon>
                image
              </mat-icon>
            </ng-template>
          </mat-cell>
        </ng-container>

      <ng-container matColumnDef="name" >
        <mat-cell *matCellDef="let element" class="col-8"> {{element.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="variant">
        <mat-cell *matCellDef="let element" class="col-5"> {{element.variant || '-'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="qty">
        <mat-cell *matCellDef="let element" class="col-2"> {{element.qty}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reassign-qty">
        <mat-cell class="d-flex align-items-center"  class="col-6" *matCellDef="let element; let i = index">
          <button type="button" mat-mini-fab color="secondary" class="mr-2" (click)="decrement(i, element)">
            <mat-icon>
              remove
            </mat-icon>
          </button>
          <span>
            <input formControlName="qty" [value]="getFormNumberValue(i)" class="soh-reassign-number-input" type="text" readonly/>
          </span>
          <button type="button" mat-mini-fab color="secondary" class="ml-2" (click)="this.increment(i, element)">
            <mat-icon>
              add
            </mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: dialogData.columnsToDisplay;" class="mt-2 d-flex"></mat-row>
      </mat-table>
      <!--
      <mat-form-field appearance="outline">
        <mat-select formControlName="selectedReason">
          <mat-option ngDefaultControl value="test">test</mat-option>
        </mat-select>
      </mat-form-field>
      -->
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mt-1 justify-content-end">
    <button mat-button
      (click)="onCancel()"
      type="button"
      *ngIf="dialogData.cancelText">
      {{ dialogData.cancelText | translate }}
    </button>

    <!-- Use without form -->
    <button *ngIf="dialogData.okText && !form"
      mat-flat-button
      [color]="okButtonType"
      (click)="onOk()"
      class="ml-3">
      {{ dialogData.okText | translate }}
    </button>

    <!-- Use with form -->
    <button *ngIf="form && dialogData.okText"
      mat-flat-button
      [color]="okButtonType"
      [disabled]="form && !form.valid"
      type="submit"
      class="ml-3">
      {{ dialogData.okText | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>