import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StoreService } from '@common/core/services';

import { IDynamicTableConfig } from '@interfaces';
import { eStoreActions } from '@web/enums';
import { SelectionModel } from '@angular/cdk/collections';
import { IFulfillmentsItems } from '@common/core/models/fulfillments.interface';

@Component({
  selector: 'soh-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  @Input() set dataSource(dataSource: any[]) {
    this._dataSource = new MatTableDataSource(dataSource);
  }
  @Input() allColumns: IDynamicTableConfig[];
  @Input() maxWidth = 'none';
  @Input() tableClass = '';
  @Input() fullWidth = false;
  @Input() excludedColumns: string[] = [];
  @Input() showHeader = true;
  @Input() showSelect: boolean = false;
  @Input() columnsToDisplaySmallSet: string[];
  @Input() columnsToDisplayMediumSet: string[];
  @Input() columnsToDisplayFullSet: string[];
  @Output() selectedRowsUpdated = new EventEmitter<IFulfillmentsItems[]>();
  @Input() selectableFunction: (itemSku: any) => boolean = (itemSku: any) => false;

  public selectedRows = new SelectionModel<IFulfillmentsItems>(true);
  columnsToDisplayByViewport = [];
  displayedColumns: IDynamicTableConfig[] = [];
  _dataSource: MatTableDataSource<any>;

  get columnIdentifiers(): string[] {
    let columns = this.displayedColumns.map(column => column.property);
    if (this.showSelect) {
      columns = [...columns, 'select'];
    }
    return columns;
  }

  areAllPacked(): boolean {
    let result = true;
    this._dataSource.data.forEach(item => {
      if (!item.isPacked) {
        result = false;
      }
    });
    return result;
  }

  areAllSelected(): boolean {
    let result = true;
    let selectableRowsNumber = 0;
    this._dataSource.data.forEach(item => {
      if (!item.isPacked) {
        selectableRowsNumber++;
      }
    });
    if (this.selectedRows.selected.length != selectableRowsNumber) {
      result = false;
    }
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedRows.clear();
  }

  ngAfterViewInit() {
    this._dataSource.sort = this.sort;
  }

  constructor(private storeService: StoreService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initColumnsSets();
    this.initTableColumns();
    this.updateDisplayedColumns();
    this.storeService.selectStore(eStoreActions.BREAKPOINT).subscribe(() => {
      this.initTableColumns();
      this.updateDisplayedColumns();
    });
  }

  public getSelectedRow(): any[] {
    return this.selectedRows.selected;
  }

  private updateDisplayedColumns() {
    this.displayedColumns = this.allColumns.filter(
      column =>
        !this.excludedColumns.includes(column.property) &&
        this.columnsToDisplayByViewport.includes(column.property)
    );
    this.changeDetectorRef.detectChanges();
  }

  private initColumnsSets() {
    if (!this.columnsToDisplayFullSet?.length) {
      this.columnsToDisplayFullSet = this.allColumns.map(column => column.property);
    }

    if (!this.columnsToDisplayMediumSet?.length) {
      this.columnsToDisplayMediumSet = [...this.columnsToDisplayFullSet];
    }

    if (!this.columnsToDisplaySmallSet?.length) {
      this.columnsToDisplaySmallSet = [...this.columnsToDisplayMediumSet];
    }
  }

  protected initTableColumns() {
    if (this.storeService.store.activeBreakpoints.isHandsetPortrait) {
      this.columnsToDisplayByViewport = this.columnsToDisplaySmallSet;
    } else if (
      this.storeService.store.activeBreakpoints.isHandsetLandscape ||
      this.storeService.store.activeBreakpoints.isTabletPortrait ||
      this.storeService.store.activeBreakpoints.isMedium ||
      this.storeService.store.activeBreakpoints.isWebPortrait
    ) {
      this.columnsToDisplayByViewport = this.columnsToDisplayMediumSet;
    } else {
      this.columnsToDisplayByViewport = this.columnsToDisplayFullSet;
    }
  }

  toggleSelect(item: any) {
    if (this.selectedRows.isSelected(item)) {
      this.selectedRows.deselect(item);
      this.selectedRowsUpdated.emit(this.selectedRows.selected);
    } else {
      this.selectedRows.select(item);
      this.selectedRowsUpdated.emit(this.selectedRows.selected);
    }
  }

  toggleSelectAll(event: any) {
    if (event.checked) {
      this._dataSource.data.forEach(item => {
        if (!item.isPacked && !this.selectedRows.isSelected(item)) {
          this.selectedRows.select(item);
          this.selectedRowsUpdated.emit(this.selectedRows.selected);
        }
      });
    } else {
      this._dataSource.data.forEach(item => {
        if (item.isPacked && this.selectedRows.isSelected(item)) {
          this.selectedRows.deselect(item);
          this.selectedRowsUpdated.emit(this.selectedRows.selected);
        }
      });
    }
  }
}
