<soh-expandable-panel [title]="'COMMON.LABEL.ITEMS' | translate"
                      *ngIf="orderItems"
                      [isExpanded]="isExpanded">

  <soh-dynamic-table [dataSource]="orderItems"
                     [tableClass]="'cells-vertical-align-top border-on-cell-bottom ' + tableClass"
                     [fullWidth]="true"
                     [excludedColumns]="excludedColumns || []"
                     [columnsToDisplayFullSet]="['image','sku','name','option1', 'option2','option3','qty','actions']"
                     [columnsToDisplayMediumSet]="['image','sku','name','optionsMerged','qty','actions']"
                     [allColumns]="[
    {
      property: 'image',
      title: 'COMMON.LABEL.PRODUCT_IMAGE' | translate,
      template: imageColumn,
      classHeader:'pr-0 pl-1',
      classRow:'p-0'
    },
    {
      property: 'sku',
      title: 'COMMON.LABEL.PRODUCT_SKU' | translate,
      template: skuColumn,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1 text-nowrap'
    },
    {
      property: 'name',
      title: 'COMMON.LABEL.PRODUCT_NAME' | translate,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    },
    {
      property: 'option1',
      title: 'COMMON.LABEL.OPTION_1' | translate,
      template:option1Column,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    },
    {
      property: 'option2',
      title: 'COMMON.LABEL.OPTION_2' | translate,
      template: option2Column,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    },
    {
      property: 'option3',
      title: 'COMMON.LABEL.OPTION_3' | translate,
      template: option3Column,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    },
    {
      property: 'optionsMerged',
      title: 'COMMON.LABEL.OPTIONS' | translate,
      template: optionsMergedColumn,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    },
    {
      property: 'qty',
      title: 'COMMON.LABEL.PRODUCT_QTY' | translate,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    },
    {
      property: 'actions',
      title: '' | translate,
      template: actionsColumn,
      classHeader:'pr-0 pl-1',
      classRow:'pr-0 pl-1'
    }
    ]">
  </soh-dynamic-table>

  <mat-toolbar *ngIf="totalPrice!==undefined"
               class="justify-content-end total-price-toolbar">
    <span class="total-string">{{'COMMON.LABEL.TOTAL' | translate}}</span>
    <span class="total-value ml-2">
      {{totalPrice | bitfCurrency:currency}}
    </span>
  </mat-toolbar>

</soh-expandable-panel>

<ng-template #imageColumn
             let-item="item">
  <ng-container *ngIf="item.imageUrl; else withoutImage">
    <img class="product-image"
         [src]="item.imageUrl" />
  </ng-container>
  <ng-template #withoutImage>
    <mat-icon>
      image
    </mat-icon>
  </ng-template>
</ng-template>

<ng-template #actionsColumn  let-item="item">
  <ng-container>
    <th mat-header-cell
        *matHeaderCellDef></th>
    <td mat-cell>
      <div class="d-flex w-100 justify-content-end">
        <button mat-icon-button
                color="primary"
                (click)="preventDefault($event)">
          <mat-icon>visibility</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
</ng-template>

<ng-template #skuColumn
             let-item="item">
  {{item.sku || '--'}}
</ng-template>

<ng-template #option1Column
             let-item="item">
  {{item.option1 ? item.option1 : '-'}}
</ng-template>

<ng-template #option2Column
             let-item="item">
  {{item.option2 ? item.option2 : '-'}}
</ng-template>

<ng-template #option3Column
             let-item="item">
  {{item.option3 ? item.option3 : '-'}}
</ng-template>

<ng-template #optionsMergedColumn
             let-item="item">
  {{item.option1 ? item.option1 : ''}}
  {{item.option2 ? item.option2 : ''}}
  {{item.option3 ? item.option3 : ''}}
</ng-template>