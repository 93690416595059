<div>
  <ng-container *ngFor="let option of options">
    <mat-checkbox [disabled]="formItem.isDisabled || !canAction()"
      color="primary"
      class="text-left"
      [checked]="option.checked"
      (change)="onOptionChange($event, option.id)">
      <span class="label-wrap">{{ option.label }}</span>
    </mat-checkbox>
  </ng-container>
</div>