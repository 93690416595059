// eslint-disable-next-line max-len
import {
  BitfGraphQlPaginationApiRequestPart,
  BitfGraphQlSortApiRequestPart,
  BitGraphQlFormItemConfigApiRequestPart,
} from '@bitf/core/api-call-state/bitf-graph-ql-api-call-state';

import {
  EApiCallStateNames,
  EApiRequestPartKeys,
  EBitfApiRequestPartsNames,
  EBitfApiSortDirection,
} from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import { WarehouseFilterApiRequestPart } from './warehouse-filter.api-request-part';
import {
  BitfNetFormItemConfigApiRequestPart,
  BitfNetPaginationApiRequestPart,
  BitfNetSortApiRequestPart,
} from '@common/libs/bitforce/core/api-call-state/bitf-net-api-call-state';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.PICKUP_IN_STORE_LIST,
    requestParts: [
      new BitfNetPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfNetFormItemConfigApiRequestPart(),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.PICKUP_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'pickupNumber', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.PICKUP_ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'orderNumber', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.PICKUP_CREATED_ON_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'createdOn', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.PICKUP_CUSTOMER_FULL_NAME,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'customerFullName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_COUNT_RETURNS,
    requestParts: [
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('return_store'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_COUNT_PICKUP_IN_STORE,
    requestParts: [
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_PICKUP_IN_STORE,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'orderNumber', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_COUNT_SHIP_FROM_STORE,
    requestParts: [
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_RETURNS,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'ordernumber', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('return_store'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_SHIP_FROM_STORE,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'ordernumber', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.SHIP_FROM_STORE_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfNetFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SHIP_FROM_STORE_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'shipFromStoreNumber', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SHIP_FROM_STORE_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'createdAt', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SHIP_FROM_STORE_CUSTOMER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'customerFullName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.FULFILLMENTS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfNetFormItemConfigApiRequestPart(),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.FULFILLMENTS_CREATED_AT_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'createdAt', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.ORDERS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfNetFormItemConfigApiRequestPart(),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'orderName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'createdAt', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_STATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'fulfillmentStatus', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_PICKING_TYPOLOGY_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfNetSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_TOTAL_PRICE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'totalPrice', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.RETURNS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_ORDER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_REFUND_TYPE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_STATUS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'requestTime', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new WarehouseFilterApiRequestPart('return_store'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.TICKETS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'o_id', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_ORDER_N_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_STATUS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.TICKET_ITEMS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 999 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_ITEM_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'o_id', direction: EBitfApiSortDirection.DESC },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
