import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '@env/environment';

export const NAVIGATION_MENU = [
  {
    title: bitfToTranslate('COMMON.LABEL.O2O'),
    icon: '',
    routerLink: null,
    svgIcon: 'o2o',
    iconSize: '24px',
    isModuleEnabled: environment.clickCollect.isModuleEnabled,
    items: [
      {
        title: bitfToTranslate('COMMON.LABEL.PICKUP_IN_STORE'),
        icon: '',
        routerLink: ['/pickup-in-store'],
        svgIcon: null,
        iconSize: null,
        isModuleEnabled: environment.clickCollect.isModuleEnabled,
      },
      {
        title: bitfToTranslate('COMMON.LABEL.SHIP_FROM_STORE'),
        icon: '',
        routerLink: ['/ship-from-store'],
        svgIcon: 'ship-from-store-icon',
        iconSize: '24px',
        isModuleEnabled: environment.shipFromStore.isModuleEnabled,
      },
    ],
  },
  {
    title: bitfToTranslate('COMMON.LABEL.ORDERS'),
    icon: '',
    routerLink: ['/orders'],
    svgIcon: 'orders-icon',
    iconSize: '24px',
    isModuleEnabled: environment.orders.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.FULFILLMENTS'),
    icon: '',
    routerLink: ['/fulfillments'],
    svgIcon: 'fulfillments-icon',
    iconSize: '24px',
    isModuleEnabled: environment.fulfillments.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.SHOP'),
    icon: '',
    routerLink: ['/shop'],
    svgIcon: 'shop-icon',
    iconSize: '24px',
    isModuleEnabled: environment.shop.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.WAREHOUSES'),
    icon: '',
    routerLink: ['/warehouse'],
    svgIcon: 'warehouse-icon',
    iconSize: '24px',
    isModuleEnabled: environment.warehouse.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.SALES_CHANNELS'),
    icon: '',
    routerLink: ['/sales-channel'],
    svgIcon: 'sales-channels-icon',
    iconSize: '24px',
    isModuleEnabled: environment.salesChannel.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.SETTINGS'),
    icon: '',
    routerLink: ['/settings'],
    svgIcon: 'settings-icon',
    iconSize: '24px',
    isModuleEnabled: environment.settings.isModuleEnabled,
  },
];

export function getEnabledNavMenu() {
  return NAVIGATION_MENU.filter(obj => obj.isModuleEnabled === true);
}
