import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['getTestWebappListing', Models.Test],
  ['createTestWebapp', Models.Test],
  ['deleteTestWebapp', Models.Test],
  ['users', Models.User],
  ['getOnlineShopOrder', Models.Order],
  ['getOnlineShopOrderListing', Models.Order],
  ['updateOnlineShopOrder', Models.Order],
  ['getReturnsListing', Models.ReturnOrder],
  ['updateReturns', Models.ReturnOrder],
  ['getReturns', Models.ReturnOrder],
  ['getTicket', Models.Ticket],
  ['getTicketListing', Models.Ticket],
  ['updateTicket', Models.Ticket],
  ['getTicketItemListing', Models.TicketItem],
  ['updateTicketItem', Models.TicketItem],
  ['getCollectOrdersInStoreListing', Models.PickupInStoreOrder],
  ['getCollectOrdersInStore', Models.PickupInStoreOrder],
  ['updateCollectOrdersInStore', Models.PickupInStoreOrder],
  ['getShipFromStoreListing', Models.ShipFromStoreOrder],
  ['getShipFromStore', Models.ShipFromStoreOrder],
  ['updateShipFromStore', Models.ShipFromStoreOrder],
  // New
  ['o2o/pick-up-in-store', Models.PickUpInStore],
  ['workflow', Models.Workflow],
  ['o2o/warehouse', Models.Warehouse],
  ['ord/orders', Models.Order],
  ['sch/saleschannels', Models.SalesChannel],
  ['sch/stores', Models.Shop],
  ['usr', Models.User],
  ['order-history', Models.OrderHistory],
  ['address', Models.Address],
  ['stk/warehouses', Models.Warehouse],
]);
